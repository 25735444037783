.pi-exxchangerate{
    margin-top:1vh ;
    padding: 10px 40px;
    border: 1px solid darkgray;
}
.ex-inputs{
    margin-top: 1vh !important;
}


/* #ex-currency-select-small{
    padding: 0px !important;
} */