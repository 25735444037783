#flyoutMenu {
    width: 100vw;
    height: 100vh;
    background-color: white;
   position: fixed;
    top:  40;
    left: 0;
    transition: transform .3s
                cubic-bezier(0, .52, 0, 1);
    overflow: scroll;
    z-index: 1000;
    box-shadow:0px 0px 15px -10px rgb(0,0,0);
-webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0);
  }
   .content{
    margin-top: 10px;
   }
  #flyoutMenu.hide {
    transform: translate3d(-100vw, 0, 0);
  }
   
  #flyoutMenu.show {
    transform: translate3d(0vw, 0, 0);
    overflow: hidden;
  }
  @media only  screen and (max-width:620px){
    #flyoutMenu {
      width: 95vw;
    }
}