.App {
  text-align: center;
  height: 100vh !important;
  padding: 0px;
  border: 0px solid ;
  width: 100%;
}
pre {
  white-space: pre-wrap;  /* Wrap text to prevent horizontal overflow */
  word-wrap: break-word;  /* Break long words to prevent overflow */
  margin: 0;  /* Remove default margin */
  padding: 8px;  /* Optional: Add padding inside the pre element */
  background-color: #f5f5f5;  /* Optional: Add a background color for better readability */
  border-radius: 4px;  /* Optional: Add rounded corners */
  overflow: auto;  /* Add scrollbars if necessary */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media only screen and (max-device-width:620px) {
  .App{
    height:100% !important;
    border: 0px solid;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(128,128,128);
}

.App-link {
  color: #61dafb;
}
.info{
  margin-top: 2vh;
  border: 0px solid;
}
.small-button{
  text-transform: none !important;
  display: 'inline-block';
  padding: 2px 4px !important;
  min-height: 0;
  min-width: 0;
  font-size: 0.7rem !important;

}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
